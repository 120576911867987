'use client';

import NextLink from 'next/link';
import { Heading, Link } from '@mezzoforte/forge';
import { Container } from 'components/Container/Container';
import { type Metadata } from 'next';

export const metadata: Metadata = {
  title: 'Sivua ei löytynyt | Huutokaupat.com',
};

export default function NotFoundPage() {
  return (
    <Container mt={6}>
      <Heading variant="h1">Sivua ei löytynyt</Heading>
      <p>Etsimääsi sivua ei löytynyt. Jos kirjoitit osoitteen käsin, tarkista oikeinkirjoitus.</p>
      <p>
        <Link as={NextLink} href="/">
          Palaa etusivulle
        </Link>
      </p>
    </Container>
  );
}
